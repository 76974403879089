.sphere-animation {
    max-width: 100%;

}

.sphere {
    fill: rgba(239, 239, 240, 1);
    stroke-width: 4px;
    stroke: rgba(80, 80, 80, .35);
    backface-visibility: hidden;
    max-height: 250px;
}

.products__sphere.sphere {
    fill: url(#sphereGradient);
    stroke-width: 2px;
    stroke: rgba(80, 80, 80, .35);
    backface-visibility: hidden;
    max-height: 250px;
}

@media screen and (min-width: 768px) {
    .sphere {
        position: absolute;
        top: 0;
        right: 0;
    }

    .sphere-animation {
        position: relative;
    }
}

@media screen and (min-width: 768px) {
.products__sphere.sphere-animation {
        transform: translateX(-25px);
    }
}


@media (min-width: 500px) {
    .sphere path {
        stroke-width: 3px;
    }
}

.animation-wrapper {
    transition: transform 0.6s;
}

.scaled {
    transform: scale(0.8);
}