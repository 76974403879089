.sphere-animation {
  max-width: 100%;
}

.sphere {
  fill: #efeff0;
  stroke-width: 4px;
  stroke: #50505059;
  backface-visibility: hidden;
  max-height: 250px;
}

.products__sphere.sphere {
  fill: url("#sphereGradient");
  stroke-width: 2px;
  stroke: #50505059;
  backface-visibility: hidden;
  max-height: 250px;
}

@media screen and (width >= 768px) {
  .sphere {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sphere-animation {
    position: relative;
  }

  .products__sphere.sphere-animation {
    transform: translateX(-25px);
  }
}

@media (width >= 500px) {
  .sphere path {
    stroke-width: 3px;
  }
}

.animation-wrapper {
  transition: transform .6s;
}

.scaled {
  transform: scale(.8);
}
/*# sourceMappingURL=certifications.c0748d8c.css.map */
